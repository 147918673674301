import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Spinner, Alert } from 'react-bootstrap'
import { isSubscribed, sendOTP, resendOPT } from 'api/shipping'
import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'
import Footer from 'components/Footer'
import Header from 'components/Header'

// css used from RegistrationForm.jsx for consistency

const StyledVerifyContent = styled.div`
  font-family: Open Sans;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(17, 17, 17, 0);
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;

  form {
    text-align: left;
    margin: 0 auto;
  }

  label,
  .get-estimates-form label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-top: 15px;
  }

  .alert {
    color: #e00000;
    padding: 1rem;
  }

  input[type='text'],
  input[type='tel'] {
    border-radius: 0;
    border-width: 3px;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-color: #e9ecef;
    height: 3rem;
    text-align: left !important;
    width: 100%;
  }

  #OptInLabel {
    font-size: 0.8rem;
    position: relative;
  }
  .concents-checkbox {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .form-control {
    padding: 0.5rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.25rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .btn-primary {
    width: 100%;
    color: white;
    background-color: #001996;
    border-color: #001996;
  }

  .btn-secondary {
    width: 100%;
    color: #001996;
    background-color: #ffffff;
    border: 1px solid #001996;
    margin-top: 10px;
  }

  .btn-secondary:hover {
    background-color: #ffffff;
    color: #0a38e6;
    border: 1px solid #0a38e6;
  }

  .btn-secondary:not(:disabled):not(.disabled):active {
    color: #001996;
    background-color: #ffffff;
    border: 2px solid #001996;
  }

  [type='submit']:not(:disabled) {
    cursor: pointer;
    -webkit-appearance: button;
  }
  .form-copy-error {
    color: #f00;
    border-color: #f00 !important;
  }
  .cta-box-col-2 div {
    max-width: 300px;
  }

  #firstname-error,
  #lastname-error,
  #phone-error,
  #optin-error {
    text-align: left;
    font-size: 11px;
    color: red;
  }
  .contacterror {
    color: #ff0004;
  }
  .contactnormal {
    color: #000000;
  }
  @media only screen and (min-width: 992px) {
    .cta-box-col-2 div {
      margin-left: 50px;
    }
  }
`
const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  .body {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
    height: 100%;
  }
  @media (min-width: 768px) {
    .body {
      padding-left: 30%;
      padding-right: 30%;
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }
  .footer {
    bottom: 0px;
  }
`
const Heading = styled.h4`
  text-align: center;
  display: inline;
  font-size: 25px;
  color: ${colors.primary_blue};
  @media ${breakpoints.desktop} {
    width: 100%;
    display: block;
    line-height: 22px;
    margin-bottom: 20px;
  }
`
const StyledButtonContainer = styled.div`
  @media ${breakpoints.tablet_above} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  }
`
// use error codes to retrieve relevant error message strings

// TODO: the error messages might be replaced depending on other tickets
const getVerificationCodeErrorMessage = (errorStatus, t) => {
  switch (errorStatus) {
    case 2:
      return t('subscription.verification_code_invalid_response')
    case 5:
      return t('verificationPopup.verification_code_expired_error')
    case 6:
      return t('subscription.verification_code_max_attempts_error')
    default:
      return t('subscription.general_error_message')
  }
}
const getAlertMessage = (t, errorStatus) => {
  switch (errorStatus) {
    case 1:
      return t('subscription.general_error_message')
    case 4:
      return t('subscription.phone_number_invalid_error')
    case 7:
      return t('forgotemail.missing_number')
    default:
      return null
  }
}

const ForgotEmailForm = () => {
  const { t, i18n } = useTranslation()
  // 0: not submitted, 1: loading, 2: submitted, 3: verifying, 4: success
  const [formStep, setFormStep] = useState(0)
  // 0: no error, 1: send verification code error, 2: verify verification code error,
  // 3: subscribe error, 4: invalid phone number, 5: expired verification code, 6: maximum verification code attempts reached
  // 7: unregistered error
  const [errorStatus, setErrorStatus] = useState(0)
  const [sendAgainDisabled, setSendAgainDisabled] = useState(false)
  const [verifyDisabled, setVerifyDisabled] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const linkContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    padding: '10px 0', // Add padding here
  }
  useEffect(() => {
    if (sendAgainDisabled) {
      const zeroAttemptsTimeout = setTimeout(() => {
        setSendAgainDisabled(false)
        setErrorStatus(0)
      }, 1000 * 30)

      return () => {
        clearTimeout(zeroAttemptsTimeout)
      }
    }

    if (verifyDisabled) {
      const maxAttemptsTimeout = setTimeout(() => {
        setVerifyDisabled(false)
        setErrorStatus(0)
      }, 1000 * 30)

      return () => {
        clearTimeout(maxAttemptsTimeout)
      }
    }

    return null
  }, [sendAgainDisabled, verifyDisabled])

  const {
    control,
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const formatNumber = (num) => {
    return num.replace(/[^\d]/g, '')
  }
  const displayFormatNumber = (num) => {
    const cleaned = num.replace(/[^\d]/g, '')
    // format the phone number to display with * except the last 3 digits
    if (cleaned.length === 11 && cleaned.startsWith('1')) {
      return cleaned.slice(0, -3).replace(/\d/g, '*') + cleaned.slice(-3)
    }
    return cleaned
  }

  const sendCode = (values) => {
    // Template syntax follows url-template https://www.npmjs.com/package/url-template
    const phonenumber = formatNumber(values.phoneNumber)
    setPhoneNumber(phonenumber)
    isSubscribed(phonenumber)
      .then((resp) => {
        // TODO: Remove true, set to true for testing purposes only
        const subscribed = resp.data
        if (!subscribed) {
          console.log('not subscribed')
          // if phone not is not in system
          setErrorStatus(7)
          setErrorTitle(t('forgotemail.missing_number'))
          setFormStep(0)
        } else {
          const lang = i18n.language
          const prn = `PYWWeb:firstname.lastname:${i18n.language}`

          setFormStep(1)
          console.log(phonenumber, lang, prn)
          sendOTP(phonenumber, lang, prn)
            .then((result) => {
              // TODO: uncomment the conditions and remove console log for production (commented for testing purposes only)
              console.log(result)
              if (
                result.data.status.success === 'true' &&
                (result.data.data[0].response === 'SUCCESS' ||
                  result.data.data[0].response === 'VERIFICATION_CODE_ALREADY_EXISTS')
              ) {
                setFormStep(2)
                setErrorStatus(0)
                return true
              }
              if (result.data.data[0].response === 'PHONE_IS_NOT_MOBILE') {
                setErrorStatus(4)
                setFormStep(0)
                return true
              }
              setErrorStatus(1)
              setFormStep(0)
              return true
            })
            .catch((err) => {
              //     // eslint-disable-next-line no-console
              console.error('Failed to connect to API', err)
              setErrorStatus(1)
              setFormStep(0)
            })
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Failed to connect to API', err)
        setErrorStatus(1)
        setFormStep(0)
      })
  }
  // Resend OTP Logic reused from RegistrationForm.jsx

  const resendCodeHandler = () => {
    const lang = i18n.language
    const prn = `PYWWeb:firstname.lastname:${i18n.language}`

    setSendAgainDisabled(true)
    resendOPT(formatNumber(getValues('phoneNumber')), lang, prn)
      .then((result) => {
        if (result.status === 200 && typeof result.data.data !== 'undefined') {
          if (
            result.data.data.length > 0 &&
            result.data.data[0].response === 'MAX_VERIFICATIONCODE_RESEND_LIMIT'
          ) {
            setErrorStatus(6)
            setFormStep(2)
            setSendAgainDisabled(true)
            return true
          }
        }
        return true
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Failed to connect to API', err)
      })
  }

  // TODO: function to verify OTP once the backend PR is approved
  const verifyCode = (values) => {
    console.log('verifying otp:', values)
  }

  const submitForm = async (fields) => {
    switch (formStep) {
      case 0:
        sendCode(fields)
        break
      case 2:
        verifyCode(fields)
        break
      default:
        break
    }
  }
  // TODO: Function for the Go Back button
  const goBack = () => {}
  return (
    <StyledBackground>
      <Header />
      <div className="body flex-grow-1 align-items-center justify-content-center">
        <StyledVerifyContent>
          <Heading className="heading">{t('forgotemail.title')}</Heading>
          <form onSubmit={handleSubmit(submitForm)} style={{ paddingTop: '20px' }}>
            {(formStep === 0 || formStep === 1) && errorTitle !== '' && (
              <p style={{ color: '#E00000', fontSize: '18px', textAlign: 'center' }}>
                {errorTitle}
              </p>
            )}
            {(formStep === 0 || formStep === 1) && (
              <>
                <p style={{ textAlign: 'center' }}>
                  {errorTitle === '' && t('forgotemail.heading')}
                </p>
                <div className="form-group">
                  <Controller
                    control={control}
                    name="phoneNumber"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: t('subscription.phone_number_invalid_error'),
                      },
                      validate: (value) => {
                        const onlyNumbers = formatNumber(value) // Remove non-digit characters
                        if (onlyNumbers.length !== 11) {
                          return t('subscription.phone_number_invalid_error')
                        }
                        return true
                      },
                    }}
                    render={({ field: { name, value, ref, onChange } }) => (
                      <PatternFormat
                        format="1 ### ### ####"
                        displayType="input"
                        type="tel"
                        name={name}
                        value={value}
                        getInputRef={ref}
                        onChange={onChange}
                        placeholder={t('forgotemail.placeholder')}
                      />
                    )}
                  />
                  <span role="alert" id="phone-error">
                    {errors.phoneNumber?.message}
                  </span>
                </div>
                <div style={{ fontSize: '13px', padding: '0px' }}>
                  {errorStatus !== 0 && (
                    <Alert
                      variant="light"
                      style={{ paddingTop: '0px', paddingBottom: '0px', marginBottom: '0px' }}
                    >
                      {getAlertMessage(t, errorStatus)}
                    </Alert>
                  )}
                </div>
              </>
            )}
            {(formStep === 0 || formStep === 1) && (
              <StyledButtonContainer>
                <Button
                  type="submit"
                  className="btn-primary"
                  disabled={verifyDisabled}
                  style={{ marginTop: '10px' }}
                >
                  {formStep === 0 ? (
                    'Submit'
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
                <Button onClick={goBack} className="btn-secondary">
                  {t('forgotemail.go_back')}
                </Button>
              </StyledButtonContainer>
            )}
            {(formStep === 2 || formStep === 3) && (
              <>
                <div className="row" style={{ paddingTop: '20px' }}>
                  <div className="col" style={{ textAlign: 'center' }}>
                    <Trans
                      t={t}
                      i18nKey="forgotemail.verificationTitle"
                      values={{ phoneNumber: displayFormatNumber(phoneNumber) }}
                      components={[<b />]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <input
                      type="text"
                      name="verificationCode"
                      aria-invalid={errors.verificationCode ? 'true' : 'false'}
                      placeholder={t('forgotemail.verificationPlaceholder')}
                      {...register('verificationCode', {
                        required: {
                          value: true,
                          message: t('subscription.verification_code_empty_error'),
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: t('subscription.verification_code_invalid_error'),
                        },
                        maxLength: {
                          value: 6,
                          message: t('subscription.verification_code_invalid_error'),
                        },
                        minLength: {
                          value: 6,
                          message: t('subscription.verification_code_invalid_error'),
                        },
                      })}
                    />
                    <div id="firstname-error">{errors.verificationCode?.message}</div>
                  </div>
                </div>
                <div className="row text-center" style={{ paddingTop: '10px' }}>
                  <div className="col">
                    <p className="small">
                      {t('verificationPopup.send_again_message')}
                      <Button
                        variant="link"
                        onClick={resendCodeHandler}
                        disabled={sendAgainDisabled}
                      >
                        {t('verificationPopup.send_again_button')}
                      </Button>
                    </p>
                  </div>
                </div>
                {errorStatus !== 0 ? (
                  <Alert variant="light">{getVerificationCodeErrorMessage(errorStatus, t)}</Alert>
                ) : null}
              </>
            )}
            {(formStep === 2 || formStep === 3) && (
              <Button type="submit" className="btn-primary" disabled={verifyDisabled}>
                {formStep === 2 ? (
                  t('subscription.verify_button')
                ) : (
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            )}
            <div style={linkContainerStyle}>
              <Trans
                t={t}
                i18nKey="forgotemail.no_account"
                components={[
                  <a href="/#" target="_blank" rel="noreferrer">
                    {t('forgotemail.forgotemail')}
                  </a>,
                ]}
              />
            </div>
            <div style={linkContainerStyle}>
              <Trans
                t={t}
                i18nKey="forgotemail.continue_guest"
                components={[
                  <a href="/#" target="_blank" rel="noreferrer">
                    {t('forgotemail.continue_guest')}
                  </a>,
                ]}
              />
            </div>
          </form>
        </StyledVerifyContent>
      </div>
      <Footer />
    </StyledBackground>
  )
}

export default ForgotEmailForm
