import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CovidBanner from 'components/CovidBanner'
import ShippingInfo from 'pages/ShippingInfo'
import NotFound from 'pages/NotFound'
import Locker from 'pages/Locker'
import CansDoorKnocker from 'pages/CansDoorKnocker'
import SupportPage from 'pages/SupportPage'
import { useTranslation } from 'react-i18next'
import SubscribePYWPage from 'pages/Registration/Registration'
import ForgotEmailForm from 'pages/ForgotEmailForm/ForgotEmailForm'

// eslint-disable-next-line react/prop-types
const AppTemplate = ({ component, hideFaqs, hideCovidBanner }) => {
  const { t } = useTranslation('translation')
  return (
    <div className="app">
      <a style={{ position: 'absolute' }} aria-label="app" href="#main">
        {t('skip_to_main')}
      </a>
      <header className="app-header">
        <Header />
      </header>
      <main id="main" className="app-content">
        {!hideCovidBanner && <CovidBanner />}
        {component}
      </main>
      <footer className="app-footer">
        <Footer hideFaqs={hideFaqs} />
      </footer>
    </div>
  )
}

const Router = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate replace to="/rs/tr/en" />} />
        <Route path="*" element={<Navigate replace to="/rs/tr/en" />} />
        <Route path="/rs/tr/:lang/*" element={<AppTemplate component={<NotFound />} />} />
        <Route path="/rs/tr/:lang/:pin" element={<AppTemplate component={<ShippingInfo />} />} />
        <Route
          path="/rs/tr/:lang/support"
          element={<AppTemplate hideCovidBanner component={<SupportPage />} />}
        />
        <Route
          exact
          path="/locker/qrcode/:lang/:pickupCode/:shipmentPin"
          element={<AppTemplate component={<Locker />} hideFaqs hideCovidBanner />}
        />
        <Route
          exact
          path="/cans/dk/:lang/:pinAndRetailCode"
          element={<AppTemplate component={<CansDoorKnocker />} hideFaqs hideCovidBanner />}
        />
        <Route
          path="/cansAndLocker/dk/:lang/support"
          element={<AppTemplate hideCovidBanner hideFaqs component={<SupportPage />} />}
        />
        <Route
          path="/rs/tr/:lang/register"
          element={<AppTemplate hideCovidBanner hideFaqs component={<SubscribePYWPage />} />}
        />
        <Route path="/rs/tr/:lang/forgotemail" element={<ForgotEmailForm />} />
      </Routes>
    </div>
  )
}

export default Router
