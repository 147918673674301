import React from 'react'
import styled from 'styled-components'
import RegistrationForm from 'components/RegistrationForm/RegistrationForm'

const StyledBackground = styled.div`
  height: 100%;

  .body {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 80px;
    padding-bottom: 24px;
  }

  @media (min-width: 768px) {
    .body {
      padding-left: 25%;
      padding-right: 25%;
    }
  }
`

const SubscribePYWPage = () => {
  return (
    <StyledBackground fluid className="d-flex flex-column min-vh-80">
      {/* <Heading>Create an Account</Heading> */}
      <div className="body flex-grow-1 d-flex align-items-center justify-content-center">
        <RegistrationForm />
      </div>
    </StyledBackground>
  )
}

export default SubscribePYWPage
